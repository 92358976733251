import axios from "axios";
import React, { useState } from "react";
import ActionSubmited from "../ActionSubmited";
import Hours from "./Hours";

const Shop = ({ shop, id }) => {
	const [phoneUpdate, setPhoneUpdate] = useState(false);
	const [phoneValue, setPhoneValue] = useState(shop.properties.phone);

	const [mailUpdate, setMailUpdate] = useState(false);
	const [mailValue, setMailValue] = useState(shop.properties.email);

	const [isClosedUpdate, setIsClosedUpdate] = useState(false);
	const [isClosedValue, setIsClosedValue] = useState(shop.properties.isClosed);

	const [textReviewUpdate, setTextReviewUpdate] = useState(false);
	const [textReviewValue, setTextReviewValue] = useState(shop.review.text);

	const [linkReviewUpdate, setLinkReviewUpdate] = useState(false);
	const [linkReviewValue, setLinkReviewValue] = useState(shop.review.link);

	const [haveBeenModified, setHaveBeenModified] = useState(false);



	// phone number update
	const handlePhoneUpdate = () => {
		setPhoneUpdate(!phoneUpdate);
	};
	const handlePhoneSubmit = async (e) => {
		e.preventDefault();

		setHaveBeenModified(true);
		setTimeout(() => {
			setHaveBeenModified(false);
		}, 3000);

		await axios({
			method: "post",
			url: `${process.env.REACT_APP_API_URL}api/shop/phone/${id}`,
			withCredentials: true,
			data: { phoneValue },
		})
			.then((res) => {
				setPhoneUpdate(false);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	// mail update
	const handleMailUpdate = () => {
		setMailUpdate(!mailUpdate);
	};
	const handleMailSubmit = async (e) => {
		e.preventDefault();

		setHaveBeenModified(true);
		setTimeout(() => {
			setHaveBeenModified(false);
		}, 3000);

		await axios({
			method: "post",
			url: `${process.env.REACT_APP_API_URL}api/shop/mail/${id}`,
			withCredentials: true,
			data: { mailValue },
		})
			.then((res) => {
				setMailUpdate(false);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const handleTextReviewUpdate = () => {
		setTextReviewUpdate(!textReviewUpdate);
	};

	const handleTextReviewSubmit = async (e) => {
		e.preventDefault();

		setHaveBeenModified(true);
		setTimeout(() => {
			setHaveBeenModified(false);
		}, 3000);

		await axios({
			method: "post",
			url: `${process.env.REACT_APP_API_URL}api/shop/textReview/${id}`,
			withCredentials: true,
			data: { textReviewValue },
		})
			.then((res) => {
				setTextReviewUpdate(false);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const handleLinkReviewUpdate = () => {
		setLinkReviewUpdate(!linkReviewUpdate);
	};

	const handleLinkReviewSubmit = async (e) => {
		e.preventDefault();

		setHaveBeenModified(true);
		setTimeout(() => {
			setHaveBeenModified(false);
		}, 3000);

		await axios({
			method: "post",
			url: `${process.env.REACT_APP_API_URL}api/shop/linkReview/${id}`,
			withCredentials: true,
			data: { linkReviewValue },
		})
			.then((res) => {
				setLinkReviewUpdate(false);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	// open/close update
	const handleIsClosedUpdate = () => {
		setIsClosedUpdate(!isClosedUpdate);
	};
	const handleIsClosedSubmit = async (e) => {
		e.preventDefault();

		setHaveBeenModified(true);
		setTimeout(() => {
			setHaveBeenModified(false);
		}, 3000);

		await axios({
			method: "post",
			url: `${process.env.REACT_APP_API_URL}api/shop/closed/${id}`,
			withCredentials: true,
			data: { isClosedValue },
		})
			.then((res) => {
				setIsClosedUpdate(false);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	// delete a shop
	const handleShopDelete = async (e) => {
		e.preventDefault();

		if (window.confirm("Êtes vous sûr de vouloir supprimer cette boutique ?")) {
			await axios({
				method: "delete",
				url: `${process.env.REACT_APP_API_URL}api/shop/${id}`,
				withCredentials: true,
			})
				.then((res) => {
					window.location.reload();
				})
				.catch((err) => {
					console.log(err);
				});
		}
	};

	return (
		<>
			{haveBeenModified && <ActionSubmited />}
			<div className="shop-container">
				<p className="shop-name">{shop.properties.storeid}</p>
				<div className="gray-separator"></div>
				{/* hours */}
				<Hours props={shop.properties.hours} id={id} />
				<div className="gray-separator"></div>
				{/* phone */}
				<p onClick={handlePhoneUpdate} className="shop-phone">
					{phoneValue ? phoneValue : shop.properties.phone}
				</p>
				{phoneUpdate && (
					<>
						<input type="text" className="shop-phone-input" onChange={(e) => setPhoneValue(e.target.value)} value={phoneValue} />
						<button onClick={handlePhoneSubmit} type="submit" className="shop-submit">
							{" "}
							<img src="../../assets/pictos/paper-plane-solid.svg" alt="send" />
						</button>
					</>
				)}
				{/* mail */}
				<p onClick={handleMailUpdate} className="shop-mail">
					{mailValue ? mailValue : shop.properties.email}
				</p>
				{mailUpdate && (
					<>
						<input type="text" className="shop-mail-input" onChange={(e) => setMailValue(e.target.value)} value={mailValue} />
						<button onClick={handleMailSubmit} type="submit" className="shop-submit">
							{" "}
							<img src="../../assets/pictos/paper-plane-solid.svg" alt="send" />
						</button>
					</>
				)}
				<div className="gray-separator"></div>
				{/* textReview */}
				<div className="review-handler">
					<p onClick={handleTextReviewUpdate} className="shop-textReview shop-review">
						{textReviewValue ? textReviewValue : shop.review.text}
					</p>
					{textReviewUpdate && (
						<>
							<input type="text" className="shop-textReview-input" onChange={(e) => setTextReviewValue(e.target.value)} value={textReviewValue} />
							<button onClick={handleTextReviewSubmit} type="submit" className="shop-submit">
								<img src="../../assets/pictos/paper-plane-solid.svg" alt="send" />
							</button>
						</>
					)}

					{/* linkReview */}
					<p onClick={handleLinkReviewUpdate} className="shop-linkReview shop-review">
						{linkReviewValue ? linkReviewValue : shop.review.link}
					</p>
					{linkReviewUpdate && (
						<>
							<input type="text" className="shop-linkReview-input" onChange={(e) => setLinkReviewValue(e.target.value)} value={linkReviewValue} />
							<button onClick={handleLinkReviewSubmit} type="submit" className="shop-submit">
								<img src="../../assets/pictos/paper-plane-solid.svg" alt="send" />
							</button>
						</>
					)}
				</div>


				<div className="gray-separator"></div>
				{/* is closed  */}
				<div onClick={handleIsClosedUpdate} className="shop-is-closed">
					{isClosedValue !== "" ? <p>Fermeture jusqu'au : {isClosedValue}</p> : isClosedValue === "" ? <p>Ouvert</p> : shop.properties.isClosed === "" ? <p>Ouvert</p> : <p>Fermé jusqu'au : {shop.properties.isClosed}</p>}
				</div>
				{isClosedUpdate && (
					<>
						<p>Fermeture exceptionnelle jusqu'au : </p>
						<input type="text" className="shop-isclosed-input" onChange={(e) => setIsClosedValue(e.target.value)} value={isClosedValue} />
						<button onClick={handleIsClosedSubmit} type="submit" className="shop-submit">
							{" "}
							<img src="../../assets/pictos/paper-plane-solid.svg" alt="send" />
						</button>
					</>
				)}
				{/* delete shop */}
				<div className="principal-round-btn close-window shop-delete">
					<img src="../assets/pictos/xmark-solid.svg" alt="close-icon" onClick={handleShopDelete} />
				</div>
			</div>
		</>
	);
};

export default Shop;
