import axios from "axios";
import React, { useState } from "react";
import ModifyPost from "../ModifyPost";
import PostDesactivation from "../PostDesactivation";
import TextEditor from "../TextEditor/TextEditor";

const Service = (props) => {
	const service = props.service;
	const [postModification, setPostModification] = useState(false);

	const [titleModification, setTitleModification] = useState(false);
	const [newTitle, setNewTitle] = useState(service.title);

	const handlePictureModification = () => {
		setPostModification(true);
	};

	const handleTitleModification = () => {
		setTitleModification(true);
	};
	const sendTitleModification = async (e) => {
		e.preventDefault();

		setTitleModification(false);

		await axios({
			method: "post",
			url: `${process.env.REACT_APP_API_URL}api/post/change-title/${service._id}`,
			withCredentials: true,
			data: {
				title: newTitle,
			},
		})
			.then((res) => {
				window.location.reload();
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const displayContext = props.index + 1;

	return (
		<>
			{postModification && <ModifyPost postModification={setPostModification} context={"services"} fileName={service.fileName} />}
			<li className="services-container__service" key={props.id}>
				<div className={displayContext === 1 || displayContext === 3 ? "service-container window-container" : "service-container-right window-container"}>
					{service.isvideo ? (
						<div className="service-container__video">
							<video src="../assets/videos/video-livraison.mp4" alt="video livraison" autoPlay loop />
						</div>
					) : (
						<div className="service-container__img" onClick={handlePictureModification}>
							<img crossOrigin="anonymous" src={`${process.env.REACT_APP_IMG_API_URL}uploads/` + service.picture} alt="update pic" />
						</div>
					)}

					<div className="service-container__text">
						{service.isvideo || service.title === "Enseigne éco-responsable" ? (
							<div className="service-container__text--header">
								<PostDesactivation post={service} />
								{!titleModification && <h4 onClick={handleTitleModification}>{newTitle}</h4>}
								{titleModification && (
									<div>
										<input type="text" placeholder={service.title} onChange={(e) => setNewTitle(e.target.value)} />
										<button className="classical-submit-btn" onClick={sendTitleModification}>
											Valider
										</button>
									</div>
								)}
							</div>
						) : (
							<div className="service-container__text--header">
								{!titleModification && <h4 onClick={handleTitleModification}>{newTitle}</h4>}
								{titleModification && (
									<div>
										<input type="text" placeholder={service.title} onChange={(e) => setNewTitle(e.target.value)} />
										<button className="classical-submit-btn" onClick={sendTitleModification}>
											Valider
										</button>
									</div>
								)}
								<PostDesactivation post={service} />
							</div>
						)}

						<div className="gray-separator"></div>
						<div className="service-container__text--text-editor">
							<TextEditor textId={service._id} />
						</div>
					</div>
				</div>
			</li>
		</>
	);
};

export default Service;
