import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Actuality from "../components/Actualities/Actuality";
import { UIdContext } from "../components/AppContext";
import Slider from "../components/Slider/Slider";
import TextEditor from "../components/TextEditor/TextEditor";
import { isEmpty } from "../components/Utils";

const ActualitiesPage = () => {
	const uid = useContext(UIdContext);

	const posts = useSelector((state) => state.postReducer);
	const [actualityPosts, setActualityPosts] = useState([]);

	useEffect(() => {
		let array = [];
		for (let i = 0; i < posts.length; i++) {
			if (posts[i].container === "actualities") {
				array.push(posts[i]);
			}
		}

		setActualityPosts(array);
	}, [posts]);

	return (
		<>
			{uid ? (
				<div className="page-context">
					<div className="actualities-page-container">
						<ul className="actuality-container__actuality">
							{!isEmpty(posts[0]) &&
								actualityPosts.map((actuality, index) => {
									return <Actuality actuality={actuality} id={actuality._id} />;
								})}
						</ul>
						<div className="actuality-container__actuality">
							<div className="actualities-container window-container">
								<div className="actualities-container__text">
									<h4>Retrouvez aussi...</h4>
									<div className="gray-separator"></div>
									<div className="actualities-container__text--text-editor">
										<TextEditor textId={"partnerslider"} />
									</div>
									<div className="slider-dom-container">
										<div className="slider-container ">
											<h4>Partenaires</h4>
											<Slider context={"partnerslider"} />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			) : null}
		</>
	);
};

export default ActualitiesPage;
