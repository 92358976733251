import axios from "axios";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import TextEditor from "../TextEditor/TextEditor";

import ActionSubmited from "../ActionSubmited";

const NewBlogForm = ({ rubrique, format, newBlogForm }) => {
	const userData = useSelector((state) => state.userReducer);

	// + rubrique + format

	const [title, setTitle] = useState("");

	const [bannerPic, setBannerPic] = useState();
	const [principalPic, setPrincipalPic] = useState();
	const [secondaryPic, setSecondaryPic] = useState();

	const [ctaTitle, setCtaTitle] = useState("");
	const [ctaTarget, setCtaTarget] = useState("");

	const [firstParagraph, setFirstParagraph] = useState("");
	const [secondParagraph, setSecondParagraph] = useState("");

	const [haveBeenModified, setHaveBeenModified] = useState(false);

	const handlePicture = async (e) => {
		e.preventDefault();

		const data = new FormData();
		data.append("userId", userData._id);
		data.append("container", "blog-article");

		data.append("rubrique", rubrique);
		data.append("format", format);

		data.append("title", title);
		data.append("bannerPic", bannerPic);
		data.append("principalPic", principalPic);
		data.append("secondaryPic", secondaryPic);
		data.append("ctaTitle", ctaTitle);
		data.append("ctaTarget", ctaTarget);
		data.append("firstParagraph", firstParagraph);
		data.append("secondParagraph", secondParagraph);

		await axios({
			method: "post",
			url: `${process.env.REACT_APP_API_URL}api/blog/new`,
			withCredentials: true,
			data: data,
		})
			.then((res) => {
				if (res.data.errors) {
					// TODO
					console.log(res.data.errors);
				} else {
					setHaveBeenModified(true);
					setTimeout(() => {
						setHaveBeenModified(false);
						closeUploadPic();
					}, 3000);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const closeUploadPic = (e) => {
		newBlogForm(false);
	};

	return (
		<>
			{haveBeenModified && <ActionSubmited />}{" "}
			<div className="new-blog-form-container">
				<div className="new-blog-form window-container">
					<form action="" onSubmit={handlePicture} className="upload-pic">
						<h3>Nouvel article</h3>
						{/* <h5>
						Rubrique : {rubrique} / Format : {format}
					</h5> */}
						<button className="close-window principal-round-btn" onClick={closeUploadPic}>
							&#9587;
						</button>

						<div className="new-blog-form-content">
							<div className="left-part">
								<div className="left-part-title">
									<input type="text" placeholder="Titre de l'article" onChange={(e) => setTitle(e.target.value)} required="true" />
								</div>
								<div className="left-part__banner-img">
									{bannerPic ? (
										<div className="img-submit">
											<p>Photo de bannière</p>
											<img className="img-preview" src={URL.createObjectURL(bannerPic)} alt="new-pic" />
										</div>
									) : (
										<>
											<label htmlFor="file" className="principal-submit-btn">
												+ Photo de bannière
											</label>
											<input type="file" id="file" name="file" accept=".jpg, .jpeg, .png" onChange={(e) => setBannerPic(e.target.files[0])} />
											<br />
										</>
									)}
								</div>
								<div className="left-part__principal-img">
									{principalPic ? (
										<div className="img-submit">
											<p>Photo principal</p>
											<img className="img-preview" src={URL.createObjectURL(principalPic)} alt="new-pic" />
										</div>
									) : (
										<>
											<label htmlFor="file" className="principal-submit-btn">
												+ Photo principal
											</label>
											<input type="file" id="file" name="file" accept=".jpg, .jpeg, .png" onChange={(e) => setPrincipalPic(e.target.files[0])} />
											<br />
										</>
									)}
								</div>
								<div className="left-part__secondary-img">
									{format === "double" && (
										<>
											{secondaryPic ? (
												<div className="img-submit">
													<p>Photo secondaire</p>
													<img className="img-preview" src={URL.createObjectURL(secondaryPic)} alt="new-pic" />
												</div>
											) : (
												<>
													<label htmlFor="file" className="principal-submit-btn">
														+ Photo secondaire
													</label>
													<input type="file" id="file" name="file" accept=".jpg, .jpeg, .png" onChange={(e) => setSecondaryPic(e.target.files[0])} />
													<br />
												</>
											)}
										</>
									)}
									{format === "cta" && (
										<div className="cta-option">
											<input type="text" placeholder="Titre du CTA" onChange={(e) => setCtaTitle(e.target.value)} />
											<input type="text" placeholder="Cible du CTA" onChange={(e) => setCtaTarget(e.target.value)} />
										</div>
									)}
								</div>
							</div>
							<div className="mid-part">
								<TextEditor textId={"newBlogForm"} textReturn={setFirstParagraph} />
							</div>
							<div className="right-part">
								<TextEditor textId={"newBlogForm"} textReturn={setSecondParagraph} />
							</div>
						</div>

						<div className="submit-btn-container">
							<input className="classical-submit-btn" type="submit" value="Enregistrer" />
						</div>
					</form>
				</div>
			</div>
		</>
	);
};

export default NewBlogForm;
