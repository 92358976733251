import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { modifyPost } from "../actions/user.actions";

const ModifyPost = (props) => {
    // console.log(props);
    const [file, setFile] = useState();
    const dispatch = useDispatch();
    const userData = useSelector((state) => state.userReducer);

    const [text, setTexte] = useState(props.textContent);
    const [fileName, setFileName] = useState(props.fileName);

    const handleModifyPost = (e) => {
        // e.preventDefault();

        const data = new FormData();
        data.append("userId", userData._id);
        data.append("container", props.context);
        if (file) {
            data.append("file", file);
        }
        if (text) {
            data.append("text", text);
        }
        if (fileName) {
            data.append("fileName", fileName);
        }

        setFileName(props.fileName);
        dispatch(modifyPost(data, props.context));
    };

    const closeModifyPost = (e) => {
        e.preventDefault();
        props.postModification(false);
    };

    return (
        <div className="upload-new-pic-container">
            {props.context === "indexHF" ? (
                <>
                    <div className={"upload-new-pic window-container"}>
                        <form action="" onSubmit={handleModifyPost} className="upload-pic">
                            <h3>Fichier index H/F</h3>
                            <button className="close-window principal-round-btn" onClick={closeModifyPost}>
                                &#9587;
                            </button>

                            <div className="grey-separator"></div>

                            <div className="upload-pic-container">
                                {file ? (
                                    <div className="img-submit">
                                        <h4>Fichier importé ✔️</h4>
                                        <input className="classical-submit-btn" type="submit" value="Enregistrer" />
                                    </div>
                                ) : (
                                    <>
                                        <label htmlFor="file" className="principal-submit-btn">
                                            + Importer un nouveau fichier
                                        </label>
                                        <input type="file" id="file" name="file" accept=".jpg, .jpeg, .png, .pdf" onChange={(e) => setFile(e.target.files[0])} />
                                        <br />
                                    </>
                                )}
                                {props.text && (
                                    <div className="text-container">
                                        <input type="text" name="text" placeholder={props.placeholder} onChange={(e) => setTexte(e.target.value)} />
                                        <input className="classical-submit-btn" type="submit" value="Enregistrer" />
                                    </div>
                                )}
                            </div>
                        </form>
                    </div>
                </>
            ) : (
                <div className={props.context === "pictos" ? "upload-new-pic window-container pictos-context" : "upload-new-pic window-container"}>
                    <form action="" onSubmit={handleModifyPost} className="upload-pic">
                        <h3>{props.text ? "Modification" : "Modifier la photo"}</h3>
                        <button className="close-window principal-round-btn" onClick={closeModifyPost}>
                            &#9587;
                        </button>

                        <div className="grey-separator"></div>

                        <div className="upload-pic-container">
                            {file ? (
                                <div className="img-submit">
                                    <img className="img-preview" src={URL.createObjectURL(file)} alt="new-pic" />
                                    <input className="classical-submit-btn" type="submit" value="Enregistrer" />
                                </div>
                            ) : (
                                <>
                                    <label htmlFor="file" className="principal-submit-btn">
                                        {props.context === "pictos" ? "+ Changer de Picto" : "+ Modifier la photo"}
                                    </label>
                                    <input type="file" id="file" name="file" accept=".jpg, .jpeg, .png" onChange={(e) => setFile(e.target.files[0])} />
                                    <br />
                                </>
                            )}
                            {props.text && (
                                <div className="text-container">
                                    <input type="text" name="text" placeholder={props.placeholder} onChange={(e) => setTexte(e.target.value)} />
                                    <input className="classical-submit-btn" type="submit" value="Enregistrer" />
                                </div>
                            )}
                        </div>
                    </form>
                </div>
            )}

        </div>
    );
};

export default ModifyPost;
