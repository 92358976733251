import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { isEmpty } from "../Utils";
import FooterPic from "./FooterPic";

const FooterPics = () => {
	const posts = useSelector((state) => state.postReducer);
	const [footerPics, setFooterPics] = useState([]);

	useEffect(() => {
		let array = [];
		for (let i = 0; i < posts.length; i++) {
			if (posts[i].container === "aboutus-footer") {
				array.push(posts[i]);
			}
		}

		setFooterPics(array);
	}, [posts]);

	return (
		<div className="footer-pics-container window-container">
			<h4>Modifier la fresque</h4>
			<div className="gray-separator"></div>
			<ul className="footer-pics-container__content">
				{!isEmpty(posts[0]) &&
					footerPics.map((pic, index) => {
						return <FooterPic pic={pic} key={pic._id} index={index + 1} />;
					})}
			</ul>
		</div>
	);
};

export default FooterPics;
