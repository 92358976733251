import React, { useContext, useState } from "react";
import { UIdContext } from "../components/AppContext";
import AddNewShop from "../components/Shop/AddNewShop";

import ShopsList from "../components/Shop/ShopsList";

const HomePage = () => {
	const uid = useContext(UIdContext);
	const [shopFilter, setShopFilter] = useState();

	const handleSearchShop = (e) => {
		setShopFilter(e.target.value);
	};

	return (
		<>
			{uid ? (
				<div className="page-context">
					<div className="shops-page-container">
						<div className="shops-container window-container">
							<header className="shops-header">
								<h4>Gestion des boutiques</h4>
								<div className="gray-separator"></div>
								<div className="search-and-add">
									<input className="shop-searcher" type="text" placeholder="Rechercher..." onChange={handleSearchShop} />
									<AddNewShop />
								</div>
							</header>
							<ShopsList shopFilter={shopFilter} />
						</div>
					</div>
				</div>
			) : null}
		</>
	);
};

export default HomePage;
