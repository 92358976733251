import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { isEmpty } from "../Utils";
import Picto from "./Picto";

const Pictos = () => {
	const posts = useSelector((state) => state.postReducer);
	const [pictos, setPictos] = useState([]);

	useEffect(() => {
		let array = [];
		for (let i = 0; i < posts.length; i++) {
			if (posts[i].container === "pictos") {
				array.push(posts[i]);
			}
		}

		setPictos(array);
	}, [posts]);

	return (
		<div className="pictos-container window-container">
			<h4>Pictogrammes</h4>
			<div className="gray-separator"></div>
			<ul className="pictos-container__pictos">
				{!isEmpty(posts[0]) &&
					pictos.map((picto, index) => {
						return <Picto picto={picto} key={picto._id} index={index + 1} />;
					})}
			</ul>
		</div>
	);
};

export default Pictos;
