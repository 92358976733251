import axios from "axios";
import React, { useEffect, useState } from "react";

const ActiveArticle = ({ article }) => {
	const [articleList, setArticleList] = useState([]);
	const [displayArticleList, setDisplayArticleList] = useState(false);

	const [articleFilter, setArticleFilter] = useState();

	const handleSearchArticle = (e) => {
		setArticleFilter(e.target.value);
	};

	useEffect(() => {
		const getArticlesList = async () => {
			await axios({
				method: "get",
				url: `${process.env.REACT_APP_API_URL}api/blog`,
				withCredentials: true,
			})
				.then((res) => {
					let array = [];
					for (let i = 0; i < res.data.length; i++) {
						if (res.data[i].rubrique === article.rubrique && res.data[i].isActive === false) {
							array.push(res.data[i]);
						}
					}
					setArticleList(array);
				})
				.catch((err) => {
					console.log(err);
				});
		};
		getArticlesList();
	}, [article]);

	const handleActiveArticleModification = () => {
		setDisplayArticleList(true);
	};

	const handleNewActiveArticle = (e) => {
		// console.log(e);  id to activate
		// console.log(article._id);  id to desactivate

		axios({
			method: "put",
			url: `${process.env.REACT_APP_API_URL}api/blog/setActive`,
			data: {
				toActivate: e,
				toDesactivate: article._id,
			},
			withCredentials: true,
		})
			.then((res) => {
				window.location.reload();
			})
			.catch((err) => {
				console.log(err);
			});
	};

	return (
		<>
			<li>
				<div className="active-article-container" onClick={handleActiveArticleModification}>
					<div className="active-article-header">
						<h5>{article.title}</h5>
					</div>
					<div className="active-article-img">
						<div className="img-cover-effect"></div>
						<img crossOrigin="anonymous" src={`${process.env.REACT_APP_IMG_API_URL}uploads/.` + article.bannerPic} alt={article.title} />
					</div>
				</div>
			</li>
			{displayArticleList && (
				<div className="inactive-article-list window-container">
					<h3>Choisir un article de remplacement</h3>
					<input type="text" placeholder="Rechercher un article" onChange={handleSearchArticle} />
					<ul>
						{/* {articleList.map((article) => {
							return (
								<li key={article._id} onClick={(e) => handleNewActiveArticle(article._id)}>
									<h5>{article.title}</h5>
								</li>
							);
						})} */}

						{articleList.map((article, index) => {
							for (let i = 0; i < articleList.length; i++) {
								if (articleFilter === undefined) {
									return (
										<li className="item" key={index} onClick={(e) => handleNewActiveArticle(article._id)}>
											<h5>{article.title}</h5>
										</li>
									);
								} else if (article.title.toLowerCase().includes(articleFilter)) {
									return (
										<li className="item" key={index} onClick={(e) => handleNewActiveArticle(article._id)}>
											<h5>{article.title}</h5>
										</li>
									);
								}
							}
							return "";
						})}
					</ul>
				</div>
			)}
		</>
	);
};

export default ActiveArticle;
