import axios from "axios";
import React, { useEffect, useState } from "react";

const WineAndCo = ({ wineAndCoDisplay }) => {
	const [wineAndCoData, setWineAndCoData] = useState([]);

	const closeWineAndCo = (e) => {
		e.preventDefault();
		wineAndCoDisplay(false);
	};

	useEffect(() => {
		const getShopsList = async () => {
			await axios({
				method: "get",
				url: `${process.env.REACT_APP_API_URL}api/extras/wineandco`,
				withCredentials: true,
			})
				.then((res) => {
					setWineAndCoData(res.data);
				})
				.catch((err) => {
					console.log(err);
				});
		};
		getShopsList();
	}, []);

	return (
		<>
			<div className="wine-and-co-container">
				<div className="wine-and-co window-container">
					<div className="wine-and-co__header">
						<h3>Wine and Co</h3>
						<button className="close-window principal-round-btn" onClick={closeWineAndCo}>
							&#9587;
						</button>
						<ul>
							{wineAndCoData.reverse().map((wineAndCo, index) => {
								return (
									<li key={index}>
										<div className="wine-and-co__item">
											<h5>{wineAndCo.month}</h5>
											<p>{wineAndCo.counter}</p>
										</div>
									</li>
								);
							})}
						</ul>
					</div>
				</div>
			</div>
		</>
	);
};

export default WineAndCo;
