import axios from "axios";

// posts
export const GET_POSTS = "GET_POSTS";
export const DELETE_POST = "DELETE_POST";
export const SLIDER_MODIFIER = "SLIDER_MODIFIER";

// errors
export const GET_POST_ERRORS = "GET_POST_ERRORS";

export const getPosts = (num) => {
	return (dispatch) => {
		return axios
			.get(`${process.env.REACT_APP_API_URL}api/post/`)
			.then((res) => {
				const array = res.data.slice(0, num);
				dispatch({ type: GET_POSTS, payload: array });
			})
			.catch((err) => console.log(err));
	};
};

export const deletePost = (postId) => {
	return (dispatch) => {
		return axios({
			method: "delete",
			url: `${process.env.REACT_APP_API_URL}api/post/` + postId,
		})
			.then((res) => {
				dispatch({ type: DELETE_POST, payload: { postId } });
			})
			.catch((err) => console.log(err));
	};
};

export const sliderModifier = (data) => {
	return (dispatch) => {
		return axios({
			method: "post",
			url: `${process.env.REACT_APP_API_URL}api/post/slider-modifier`,
			data: data,
		})
			.then((res) => {
				console.log(res);
				dispatch({ type: SLIDER_MODIFIER, payload: res.data });
				window.location.reload();
			})
			.catch((err) => console.log(err));
	};
};
