import React, { useContext, useState } from "react";
import { UIdContext } from "../components/AppContext";
import { NavLink } from "react-router-dom";
import CategoryModification from "../components/Blog/CategoryModification";

const BlogManager = () => {
	const uid = useContext(UIdContext);
	const [categoryModification, setCategoryModification] = useState(false);
	const [category, setCategory] = useState("");

	const handleCategoryModification = (category) => {
		setCategory(category);
		setCategoryModification(true);
	};

	return (
		<>
			{categoryModification && <CategoryModification category={category} categoryModification={setCategoryModification} />}
			{uid ? (
				<div className="page-context">
					<div className="blog-manager-page-container">
						<div className="blog-manager-container window-container">
							<h4>Blog manager</h4>
							<div className="gray-separator"></div>
							<section className="blog-manager-section">
								<h5>Tous les articles</h5>
								<NavLink to="/blog/all-articles" className="principal-submit-btn">
									Modification ou suppression
								</NavLink>
							</section>

							<section className="blog-manager-section unable">
								<h5>Modifier une catégorie</h5>

								<div className="row">
									<div className="categorie wine-pairing" onClick={(e) => handleCategoryModification("winePairing")}>
										<img src="../assets/img/wine-pairing.jpg" alt="wine-pairing" />
									</div>
									<div className="categorie novelty" onClick={(e) => handleCategoryModification("novelty")}>
										<img src="../assets/img/novelty.jpg" alt="novelty" />
									</div>
								</div>

								<div className="row">
									<div className="categorie advices" onClick={(e) => handleCategoryModification("advices")}>
										<img src="../assets/img/advices.jpg" alt="advices" />
									</div>
									<div className="categorie winemaker" onClick={(e) => handleCategoryModification("winemaker")}>
										<img src="../assets/img/winemaker.jpg" alt="winemaker" />
									</div>
								</div>
								<div className="unabled">
									<p>Cet élément n'est plus à jour et plus modifiable</p>
								</div>
							</section>
						</div>
					</div>
				</div>
			) : null}
		</>
	);
};

export default BlogManager;
