import React, { useContext } from "react";
import { UIdContext } from "../components/AppContext";
import TextEditor from "../components/TextEditor/TextEditor";

const CGUPage = () => {
	const uid = useContext(UIdContext);
	return (
		<>
			{uid ? (
				<div className="page-context">
					<div className="cgu-page-container">
						<div className="cgu-container window-container">
							<TextEditor textId={"conditionsgénéralesutilisations"} />
						</div>
					</div>
				</div>
			) : null}
		</>
	);
};

export default CGUPage;
