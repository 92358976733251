import React, { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import axios from "axios";

const ArticleModification = ({ articleId, paragraph, textReturn }) => {
	const [editorState, setEditorState] = useState(null);
	const [initialText, setInitialText] = useState("");

	useEffect(() => {
		const getTitleAndText = async () => {
			await axios({
				method: "get",
				url: `${process.env.REACT_APP_API_URL}api/blog/${articleId}/${paragraph}`,
				withCredentials: true,
			})
				.then((res) => {
					setInitialText(res.data);
					setTextToSubmit(res.data);
					const contentBlock = htmlToDraft(initialText);
					if (contentBlock) {
						const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
						const newEditorState = EditorState.createWithContent(contentState);
						setEditorState(newEditorState);
					}
				})
				.catch((err) => {
					console.log(err);
				});
		};
		getTitleAndText();
	}, [articleId, initialText, paragraph]);

	const [textToSubmit, setTextToSubmit] = useState("");

	const textUpdate = () => {
		setTextToSubmit(draftToHtml(convertToRaw(editorState.getCurrentContent())));
	};

	return (
		<>
			<div className="text-editor-container">
				<div className="text-editor">
					<Editor
						editorState={editorState}
						toolbarClassName="text-editor__toolbar"
						wrapperClassName="text-editor__wrapper"
						editorClassName="text-editor__editor"
						onEditorStateChange={setEditorState}
						onChange={textUpdate}
						localization={{
							locale: "fr",
						}}
						toolbar={{
							options: ["inline", "fontSize", "list", "textAlign", "link", "emoji", "history"],
							inline: {
								options: ["bold", "italic", "underline", "strikethrough"],
							},
							fontSize: {
								options: [8, 9, 10, 11, 12, 13, 15, 18, 24, 30, 36, 48, 60, 72, 96],
							},
							list: {
								options: ["unordered", "ordered"],
							},
							link: {
								options: ["link"],
							},
							emoji: {
								emojis: ["🍇", "🍾", "🥂", "🍷", "🍸", "🥃", "🍹", "🍺", "🍻", "🧊", "😀", "😁", "😂", "😃", "😉", "😋", "😎", "😍", "😗", "🤗", "🤔", "😣", "😫", "😴", "😌", "🤓", "😛", "😜", "😠", "😇", "😷", "😈", "👻", "😺", "😸", "😹", "😻", "😼", "😽", "🙀", "🙈", "🙉", "🙊", "👼", "👮", "🕵", "💂", "👳", "🎅", "👸", "👰", "👲", "🙍", "🙇", "🚶", "🏃", "💃", "⛷", "🏂", "🏌", "🏄", "🚣", "🏊", "⛹", "🏋", "🚴", "👫", "💪", "👈", "👉", "👉", "👆", "🖕", "👇", "🖖", "🤘", "🖐", "👌", "👍", "👎", "✊", "👊", "👏", "🙌", "🙏", "🐵", "🐶", "🐇", "🐥", "🐸", "🐌", "🐛", "🐜", "🐝", "🍉", "🍈", "🍊", "🍋", "🍌", "🍍", "🍎", "🍏", "🍐", "🍑", "🍒", "🍓", "🫐", "🍄", "🍔", "🍤", "🍨", "🍪", "🎂", "🍰", "🌍", "🚑", "⏰", "🌙", "🌝", "🌞", "⭐", "🌟", "🌠", "🌨", "🌩", "⛄", "🔥", "🎄", "🎈", "🎉", "🎊", "🎁", "🎗", "🏀", "🏈", "🎲", "🔇", "🔈", "📣", "🔔", "🎵", "🎷", "💰", "🖊", "📅", "✅", "❎", "💯"],
							},
						}}
					/>
					{/* debbug */}
					{/* <textarea disabled value={draftToHtml(convertToRaw(editorState.getCurrentContent()))} /> */}
				</div>
				{textReturn && textReturn(textToSubmit)}
			</div>
		</>
	);
};

export default ArticleModification;
