import axios from "axios";
import React, { useState } from "react";

import ArticleModification from "../TextEditor/ArticleModification";
import ActionSubmited from "../ActionSubmited";

const UpdateArticle = ({ articleModification, article }) => {
	const [title, setTitle] = useState(article.title);

	const [bannerPic, setBannerPic] = useState(article.bannerPic);
	const [principalPic, setPrincipalPic] = useState(article.principalPic);
	const [secondaryPic, setSecondaryPic] = useState(article.secondaryPic);

	const [ctaTitle, setCtaTitle] = useState(article.ctaTitle);
	const [ctaTarget, setCtaTarget] = useState(article.ctaTarget);

	const [firstParagraph, setFirstParagraph] = useState(article.firstParagraph);
	const [secondParagraph, setSecondParagraph] = useState(article.secondParagraph);

	const [haveBeenModified, setHaveBeenModified] = useState(false);

	const [deleteConfirmation, setDeleteConfirmation] = useState(false);

	const [isActive, setIsActive] = useState(article.isActive);

	const [isPicModalOpen, setIsPicModalOpen] = useState(false);
	const [selectedImageType, setSelectedImageType] = useState(null);

	const handleArticleModification = async (e) => {
		e.preventDefault();

		await axios({
			method: "post",
			url: `${process.env.REACT_APP_API_URL}api/blog/update/${article._id}`,
			withCredentials: true,
			data: {
				title: title,
				ctaTitle: ctaTitle,
				ctaTarget: ctaTarget,
				firstParagraph: firstParagraph,
				secondParagraph: secondParagraph,
			},
		})
			.then((res) => {
				if (res.data.errors) {
					// TODO
					console.log(res.data.errors);
				} else {
					setHaveBeenModified(true);
					setTimeout(() => {
						setHaveBeenModified(false);
						closeUploadPic();
						window.location.reload();
					}, 3000);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const handleArticledelete = (e) => {
		e.preventDefault();
		axios({
			method: "delete",
			url: `${process.env.REACT_APP_API_URL}api/blog/delete/${article._id}`,
			withCredentials: true,
		})
			.then((res) => {
				if (res.data.errors) {
					console.log(res.data.errors);
				} else {
					window.location.reload();
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const handleDeleteConfirmation = (e) => {
		e.preventDefault();
		setDeleteConfirmation(true);
	};

	const toggleArticleStatus = async () => {
		await axios({
			method: 'post',
			url: `${process.env.REACT_APP_API_URL}api/blog/setActive/${article._id}`,
			withCredentials: true,
		})
			.then((res) => {
				setIsActive(res.data.isActive);
				window.location.reload();
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const closeUploadPic = (e) => {
		articleModification(false);
	};



	const openPicModal = (imageType) => {
		setSelectedImageType(imageType);
		setIsPicModalOpen(true);
	};

	const closePicModal = (e) => {
		// e.preventDefault();
		setIsPicModalOpen(false);
		setSelectedImageType(null);
	};

	const [file, setFile] = useState(null);

	const handleImageChange = (e) => {
		setFile(e.target.files[0]); // Stocke la nouvelle image dans l'état
	};

	const handlePictureUpload = async (e) => {
		e.preventDefault();

		const formData = new FormData();
		formData.append('file', file); // Le fichier à uploader
		formData.append('imageType', selectedImageType); // Le type d'image à modifier

		await axios({
			method: 'post',
			url: `${process.env.REACT_APP_API_URL}api/blog/upload-picture/${article._id}`,
			data: formData,
			withCredentials: true,
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		})
			.then((res) => {
				// Mise à jour de l'image dans l'état en fonction du type d'image
				if (selectedImageType === 'bannerPic') {
					setBannerPic(res.data.updatedArticle.bannerPic);
				} else if (selectedImageType === 'principalPic') {
					setPrincipalPic(res.data.updatedArticle.principalPic);
				} else if (selectedImageType === 'secondaryPic') {
					setSecondaryPic(res.data.updatedArticle.secondaryPic);
				}
				closeUploadPic(); // Ferme la modale après succès

			})
			.catch((err) => {
				console.log(err);
			});
	};

	return (
		<>
			{haveBeenModified && <ActionSubmited />}{" "}
			<div className="new-blog-form-container">
				<div className="new-blog-form window-container">
					<form action="" onSubmit={handleArticleModification} className="upload-pic">
						<h3>Modifier l'article</h3>
						{/* <h5>
						Rubrique : {rubrique} / Format : {format}
						</h5> */}
						<button className="close-window principal-round-btn" onClick={closeUploadPic}>
							&#9587;
						</button>

						<div className="new-blog-form-content">
							<div className="left-part">
								<div className="left-part-title">
									<input type="text" placeholder={title} onChange={(e) => setTitle(e.target.value)} />
								</div>
								<div className="left-part__banner-img">
									{bannerPic ? (
										<div className="img-submit" onClick={() => openPicModal('bannerPic')}>
											<p>Photo de bannière</p>
											<img crossOrigin="anonymous" className="img-preview" src={`${process.env.REACT_APP_IMG_API_URL}uploads/.` + bannerPic} alt="new-pic" />
										</div>
									) : (
										<label htmlFor="file" className="principal-submit-btn">
											+ Photo de bannière
										</label>
									)}
								</div>
								<div className="left-part__principal-img">
									{principalPic ? (
										<div className="img-submit" onClick={() => openPicModal('principalPic')}>
											<p>Photo principale</p>
											<img crossOrigin="anonymous" className="img-preview" src={`${process.env.REACT_APP_IMG_API_URL}uploads/.` + principalPic} alt="new-pic" />
										</div>
									) : (
										<label htmlFor="file" className="principal-submit-btn">
											+ Photo principale
										</label>
									)}
								</div>
								<div className="left-part__secondary-img">
									{article.format === "double" && (
										<div className="left-part__secondary-img">
											{secondaryPic ? (
												<div className="img-submit" onClick={() => openPicModal('secondaryPic')}>
													<p>Photo secondaire</p>
													<img crossOrigin="anonymous" className="img-preview" src={`${process.env.REACT_APP_IMG_API_URL}uploads/.` + secondaryPic} alt="new-pic" />
												</div>
											) : (
												<label htmlFor="file" className="principal-submit-btn">
													+ Photo secondaire
												</label>
											)}
										</div>
									)}
									{article.format === "cta" && (
										<div className="cta-option">
											<input type="text" placeholder={article.ctaTitle} onChange={(e) => setCtaTitle(e.target.value)} />
											<input type="text" placeholder={article.ctaTarget} onChange={(e) => setCtaTarget(e.target.value)} />
										</div>
									)}
								</div>
							</div>
							<div className="mid-part">
								<ArticleModification articleId={article._id} paragraph={"firstParagraph"} textReturn={setFirstParagraph} />
							</div>
							<div className="right-part">
								<ArticleModification articleId={article._id} paragraph={"secondParagraph"} textReturn={setSecondParagraph} />
							</div>
						</div>

						<div className="submit-btn-container">
							<input className="classical-submit-btn" type="submit" value="Enregistrer" />

							{deleteConfirmation ? (
								<button className="delete-article-btn" onClick={(e) => handleArticledelete(e)}>
									Confirmer
								</button>
							) : (
								<button className="delete-article-btn" onClick={(e) => handleDeleteConfirmation(e)}>
									Supprimer
								</button>
							)}
							<div className="status-toggle">
								<button
									className={`status-btn ${isActive ? 'deactivate' : 'activate'}`}
									onClick={toggleArticleStatus}
								>
									{isActive ? 'Désactiver' : 'Activer'}
								</button>
								{/* <p>{isActive ? "L'article est actif" : 'activate'}</p> */}
							</div>
						</div>
					</form>
				</div>
				{isPicModalOpen && (
					<div className="blog-img-modal">
						<div className="modal-content">
							<form onSubmit={handlePictureUpload}>
								<h3>Modifier l'image</h3>
								<button className="close-window principal-round-btn" onClick={closePicModal}>&#9587;</button>

								<label htmlFor="file">+ Sélectionner une nouvelle image</label>
								<input type="file" id="file" accept=".jpg, .jpeg, .png" onChange={(e) => handleImageChange(e)} />

								<input type="submit" value="Enregistrer" disabled={!file} />
							</form>
						</div>
					</div>
				)}
			</div>
		</>
	);
};

export default UpdateArticle;
