import axios from "axios";
import React, { useState } from "react";
import ModifyPost from "../ModifyPost";
import TextEditor from "../TextEditor/TextEditor";

const Recrutement = (props) => {
	const recrutement = props.recrutement;

	const [postModification, setPostModification] = useState(false);

	const [titleModification, setTitleModification] = useState(false);
	const [newTitle, setNewTitle] = useState(recrutement.title);

	const handlePictureModification = () => {
		setPostModification(true);
	};

	const handleTitleModification = () => {
		setTitleModification(true);
	};
	const sendTitleModification = async (e) => {
		e.preventDefault();

		setTitleModification(false);

		await axios({
			method: "post",
			url: `${process.env.REACT_APP_API_URL}api/post/change-title/${recrutement._id}`,
			withCredentials: true,
			data: {
				title: newTitle,
			},
		})
			.then((res) => {
				window.location.reload();
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const displayContext = props.index + 1;

	return (
		<>
			{postModification && <ModifyPost postModification={setPostModification} context={"recrutement"} fileName={recrutement.fileName} />}
			<li className="recrutements-container__recrutement" key={props.id}>
				<div className={displayContext === 1 || displayContext === 3 ? "recrutement-container window-container" : "recrutement-container-right window-container"}>
					{recrutement.isvideo ? (
						<div className="recrutement-container__video">
							<iframe className="video" src="https://www.youtube.com/embed/yzpDCmaUBOw" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
						</div>
					) : (
						<>
							{recrutement.picture && (
								<div className="recrutement-container__img" onClick={handlePictureModification}>
									<img crossOrigin="anonymous" src={`${process.env.REACT_APP_IMG_API_URL}uploads/` + recrutement.picture} alt="update pic" />
								</div>
							)}
						</>
					)}

					<div className={`recrutement-container__text${!recrutement.picture ? ` full-width` : ""}`}>
						{!titleModification && <h4 onClick={handleTitleModification}>{newTitle}</h4>}
						{titleModification && (
							<div>
								<input type="text" placeholder={recrutement.title} onChange={(e) => setNewTitle(e.target.value)} />
								<button className="classical-submit-btn" onClick={sendTitleModification}>
									Valider
								</button>
							</div>
						)}
						<div className="gray-separator"></div>
						<div className="recrutement-container__text--text-editor">
							<TextEditor textId={recrutement._id} />
						</div>
					</div>
				</div>
			</li>
		</>
	);
};

export default Recrutement;
