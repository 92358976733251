import React, { useState } from "react";
import ModifyPost from "../ModifyPost";
import TextEditor from "../TextEditor/TextEditor";

const AboutUs = () => {
	const [postModification, setPostModification] = useState(false);

	const handlePictureModification = () => {
		setPostModification(true);
	};

	return (
		<>
			{postModification && <ModifyPost postModification={setPostModification} context={"aboutus-xl"} />}
			<div className="about-us-container window-container">
				<div className="about-us-container__img">
					<img crossOrigin="anonymous" src={`${process.env.REACT_APP_IMG_API_URL}uploads/aboutus-xl/aboutus-xl.jpg`} alt="A propos img" onClick={handlePictureModification} />
				</div>
				<div className="about-us-container__text">
					<h4>Qui sommes nous ?</h4>
					<div className="gray-separator"></div>
					<div className="about-us-container__text--text-editor">
						<TextEditor textId={"aboutustext"} />
					</div>
				</div>
			</div>
		</>
	);
};

export default AboutUs;
