import React, { useState } from "react";
import { timestampParser } from "../Utils";
import UpdateArticle from "./UpdateArticle";

const Article = ({ article }) => {
	const [updateAt, setUpdateAt] = useState(timestampParser(article.updatedAt));
	const [articleModification, setArticleModification] = useState(false);

	const handleArticleModification = () => {
		setArticleModification(!articleModification);
	};

	return (
		<>
			{articleModification && <UpdateArticle article={article} articleModification={setArticleModification} />}
			<div className="article-container" onClick={handleArticleModification}>
				<div className="article-info">
					<h5>{article.title}</h5>
				</div>
				<div className="article-info">
					<p>
						{article.rubrique === "winePairing" && "Accords mets/vins"}
						{article.rubrique === "novelty" && "Nouveautés"}
						{article.rubrique === "advices" && "Coup de cœur"}
						{article.rubrique === "winemaker" && "Vigneron.ne.s"}
					</p>
				</div>
				<div className="article-info">
					<p>{updateAt}</p>
				</div>
				<div className="article-info">{article.isActive ? <p className="isActive">Activé</p> : ""}</div>
			</div>
		</>
	);
};

export default Article;
