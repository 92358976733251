import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { UIdContext } from "../components/AppContext";
import Article from "../components/Blog/Article";

const BlogArticles = () => {
	const uid = useContext(UIdContext);

	const [articlesList, setArticlesList] = useState([]);
	const [articleFilter, setArticleFilter] = useState();

	const handleSearchArticle = (e) => {
		setArticleFilter(e.target.value);
	};

	useEffect(() => {
		const getArticlesList = async () => {
			await axios({
				method: "get",
				url: `${process.env.REACT_APP_API_URL}api/blog`,
				withCredentials: true,
			})
				.then((res) => {
					setArticlesList(res.data);
				})
				.catch((err) => {
					console.log(err);
				});
		};
		getArticlesList();
	}, []);

	return (
		<>
			{uid ? (
				<div className="page-context">
					<div className="blog-articles-container">
						<div className="blog-articles window-container">
							<div className="blog-articles-header">
								<h4>Tous les articles</h4>
								<div className="gray-separator"></div>
								<input type="text" placeholder="Rechercher un article" onChange={handleSearchArticle} />
								<div className="blog-articles-infos">
									<h5>Nom</h5>
									<h5>Rubrique</h5>
									<h5>Date de modification</h5>
									<div></div>
								</div>
								<div>
									<ul className="articles-list">
										{articlesList.map((article, index) => {
											for (let i = 0; i < articlesList.length; i++) {
												if (articleFilter === undefined) {
													return (
														<li className="item" key={index}>
															<Article article={article} />
														</li>
													);
												} else if (article.title.toLowerCase().includes(articleFilter)) {
													return (
														<li className="item" key={index}>
															<Article article={article} />
														</li>
													);
												}
											}
											return "";
										})}
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			) : null}
		</>
	);
};

export default BlogArticles;
