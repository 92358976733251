import { GET_POSTS, DELETE_POST, SLIDER_MODIFIER } from "../actions/post.actions";

const initialState = {};

export default function postReducer(state = initialState, action) {
	switch (action.type) {
		case GET_POSTS:
			return action.payload;

		case DELETE_POST:
			return state.filter((post) => post._id !== action.payload.postId);

		case SLIDER_MODIFIER:
			return action.payload;

		default:
			return state;
	}
}
