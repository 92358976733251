import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Draggable from "react-draggable";
import { deletePost } from "../../actions/post.actions";

const Card = (props, key) => {
	const dispatch = useDispatch();
	const picInformation = props.pic;

	const [position, setPosition] = useState(picInformation.placement);

	const removeSliderPic = () => {
		dispatch(deletePost(picInformation._id));
	};

	return (
		<>
			<li className="slider-container__draggable-components" key={key}>
				<Draggable defaultClassName="slider-draggable" bounds={{ top: 0, left: 0, right: 620, bottom: 0 }} onDrag={(e) => setPosition(e.screenX)} onLoad={(e) => console.log(e)} defaultPosition={{ x: 0 + position * 210, y: 0 }}>
					<div className="window" id={picInformation._id} value={position}>
						<div className="slider-draggable__img">
							<div className="slider-draggable__img--cover"></div>
							<img className="slider-draggable__img--pic" crossOrigin="anonymous" src={`${process.env.REACT_APP_IMG_API_URL}${picInformation.picture}`} alt="slider pic" />
						</div>
						<div className="principal-round-btn close-window">
							<img src="../assets/pictos/xmark-solid.svg" alt="close-icon" onClick={removeSliderPic} />
						</div>
					</div>
				</Draggable>
			</li>
		</>
	);
};

export default Card;
