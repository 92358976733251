import React, { useState } from "react";
import axios from "axios";
// import SignUpForm from "./SignUpForm";

const SignInForm = () => {
	const [identifier, setIdentifier] = useState("");
	const [password, setPassword] = useState("");

	const handleLogin = (e) => {
		e.preventDefault();
		const identifierError = document.querySelector(".identifier.error");
		const passwordError = document.querySelector(".password.error");

		axios({
			method: "post",
			url: `${process.env.REACT_APP_API_URL}api/user/login`,
			withCredentials: true,
			data: {
				identifier,
				password,
			},
		})
			.then((res) => {
				if (res.data.errors) {
					identifierError.innerHTML = res.data.errors.identifier;
					passwordError.innerHTML = res.data.errors.password;
				} else {
					window.location = "/";
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	return (
		<>
			{" "}
			<div className="log-page">
				<div className="log-container">
					<div className="illustration">
						<img src="../assets/logos/lrdb-white.svg" alt="logo" />
						<h1>Administrateur Dashboard</h1>
						<h4>Entrez votre identifiant et votre mot de passe</h4>
					</div>
					<form action="" onSubmit={handleLogin} id="sign-in-form" className="sign-in-form">
						<div className="log-title">
							<h2>Connexion</h2>
						</div>
						<label htmlFor="identifier"></label>
						<input type="text" name="identifier" id="identifier" onChange={(e) => setIdentifier(e.target.value)} value={identifier} placeholder="Identifiant" />
						<div className="identifier error"></div>
						<br />

						<label htmlFor="password"></label>
						<input type="password" name="password" id="password" onChange={(e) => setPassword(e.target.value)} value={password} placeholder="Mot de passe" />
						<div className="password error"></div>
						<br />
						<input className="principal-submit-btn" type="submit" value="Se connecter" />
					</form>
				</div>
			</div>
			{/* <SignUpForm /> */}
		</>
	);
};

export default SignInForm;
