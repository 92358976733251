import React from "react";

const ActionSubmited = () => {
	return (
		<>
			<div className="action-submited-container">Modification enregistrée</div>{" "}
		</>
	);
};

export default ActionSubmited;
