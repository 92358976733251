import axios from "axios";
import React, { useState } from "react";

const Cart = ({ cart, id }) => {
	const [desactivation, setDesactivation] = useState(false);
	const [activation, setActivation] = useState(false);

	const handleActivation = () => {
		setActivation(!activation);
	};
	const activationConfirmation = async () => {
		// e.preventDefault();

		await axios({
			method: "post",
			url: `${process.env.REACT_APP_API_URL}api/post/header/${id}`,
			withCredentials: true,
			data: { isActive: true, title: cart.title },
		})
			.then((res) => {
				window.location.reload();
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const handleDesactivation = () => {
		setDesactivation(!desactivation);
	};
	const desactivateConfirmation = async (e) => {
		// e.preventDefault();

		await axios({
			method: "post",
			url: `${process.env.REACT_APP_API_URL}api/post/header/${id}`,
			withCredentials: true,
			data: { isActive: false, title: cart.title },
		})
			.then((res) => {
				window.location.reload();
			})
			.catch((err) => {
				console.log(err);
			});
	};

	return (
		<>
			{cart.isActive ? (
				<li key={id}>
					{desactivation ? (
						<div className="header-section" onClick={desactivateConfirmation}>
							<img src={cart.picture} alt="header rubrique" />
							<div className="header-section__desactivation-cover">
								<h3>Désactiver cette rubrique ?</h3>
							</div>
						</div>
					) : (
						<div className="header-section" onClick={handleDesactivation}>
							<img src={cart.picture} alt="header rubrique" />
							<h3>{cart.title}</h3>
						</div>
					)}
				</li>
			) : (
				<li key={id}>
					{activation ? (
						<div className="header-section" onClick={activationConfirmation}>
							<img src={cart.picture} alt="header rubrique" />
							<div className="header-section__activation-cover">
								<h3>Activer : {cart.title} ?</h3>
							</div>
						</div>
					) : (
						<div className="header-section desactived" onClick={handleActivation}>
							<img src={cart.picture} alt="header rubrique" />
							<h3>Désactivé</h3>
						</div>
					)}
				</li>
			)}
		</>
	);
};

export default Cart;
