import React, { useContext, useState } from "react";
import { UIdContext } from "../components/AppContext";
import NewBlogForm from "../components/Blog/NewBlogForm";

const BlogCreation = () => {
	const uid = useContext(UIdContext);

	const [blogRubrique, setBlogRubrique] = useState("");
	const [blogFormat, setBlogFormat] = useState("");

	const [newBlogForm, setNewBlogForm] = useState(false);

	const handleNewBlogForm = () => {
		setNewBlogForm(!newBlogForm);
	};

	return (
		<>
			{newBlogForm && <NewBlogForm rubrique={blogRubrique} format={blogFormat} newBlogForm={setNewBlogForm} />}
			{uid ? (
				<div className="page-context">
					<div className="new-blog-page-container">
						<div className="new-blog-container window-container">
							<h4>Nouvel article de blog</h4>
							<div className="gray-separator"></div>
							<section className="new-blog-section">
								<h5>Rubrique</h5>
								<div className="cc-selector">
									<div className="blog-rubrique">
										<input id="winePairing" type="radio" name="blog-rubrique" value="winePairing" onChange={(e) => setBlogRubrique(e.target.value)} />
										<label className="drinkcard-cc winePairing" htmlFor="winePairing"></label>
									</div>

									<div className="blog-rubrique">
										<input id="novelty" type="radio" name="blog-rubrique" value="novelty" onChange={(e) => setBlogRubrique(e.target.value)} />
										<label className="drinkcard-cc novelty" htmlFor="novelty"></label>
									</div>

									<div className="blog-rubrique">
										<input id="advices" type="radio" name="blog-rubrique" value="advices" onChange={(e) => setBlogRubrique(e.target.value)} />
										<label className="drinkcard-cc advices" htmlFor="advices"></label>
									</div>

									<div className="blog-rubrique">
										<input id="winemaker" type="radio" name="blog-rubrique" value="winemaker" onChange={(e) => setBlogRubrique(e.target.value)} />
										<label className="drinkcard-cc winemaker" htmlFor="winemaker"></label>
									</div>
								</div>
							</section>

							<section className="new-blog-section">
								<h5>Format</h5>
								<div className="cc-selector">
									<div className="blog-rubrique">
										<input id="simple" type="radio" name="blog-format" value="simple" onChange={(e) => setBlogFormat(e.target.value)} />
										<label className="drinkcard-cc simple" htmlFor="simple"></label>
									</div>

									<div className="blog-rubrique">
										<input id="double" type="radio" name="blog-format" value="double" onChange={(e) => setBlogFormat(e.target.value)} />
										<label className="drinkcard-cc double" htmlFor="double"></label>
									</div>

									<div className="blog-rubrique">
										<input id="cta" type="radio" name="blog-format" value="cta" onChange={(e) => setBlogFormat(e.target.value)} />
										<label className="drinkcard-cc cta" htmlFor="cta"></label>
									</div>
								</div>
							</section>
							<div className="submit-container">
								{blogRubrique && blogFormat && (
									<button className="classical-submit-btn" onClick={handleNewBlogForm}>
										Éditer
									</button>
								)}
							</div>
						</div>
					</div>
				</div>
			) : null}
		</>
	);
};

export default BlogCreation;
