import { GET_USER, UPLOAD_PICTURE, MODIFY_POST, UPLOAD_INDEX } from "../actions/user.actions";

const initialState = {};

export default function userReducer(state = initialState, action) {
	switch (action.type) {
		case GET_USER:
			return action.payload;

		case UPLOAD_PICTURE:
			return {
				...state,
				picture: action.payload,
			};

		case UPLOAD_INDEX:
			return {
				...state,
				doc: action.payload,
			};

		case MODIFY_POST:
			return {
				...state,
				picture: action.payload,
			};

		default:
			return state;
	}
}
