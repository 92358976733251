import React from "react";
import { HashRouter, Route, Routes } from "react-router-dom";

import Home from "../../pages/Home";

import HomePage from "../../pages/HomePage";
import ShopsPage from "../../pages/ShopsPage";
import ActualitiesPage from "../../pages/ActualitiesPage";
import ServicesPage from "../../pages/ServicesPage";
import RecrutementPage from "../../pages/RecrutementPage";
import CGUPage from "../../pages/CGUPage";

import Header from "../../pages/Header";
import Press from "../../pages/Press";

import BlogCreation from "../../pages/BlogCreation";
import BlogManager from "../../pages/BlogManager";
import BlogArticles from "../../pages/BlogArticles";

import NotFound from "../../pages/NotFound";
import PrincipalNavBar from "../PrincipalNavBar";

const index = () => {
	return (
		<div className="app-delimitation">
			<HashRouter>
				<PrincipalNavBar />
				<Routes>
					<Route path="/" exact element={<Home />} />

					<Route path="/page/home" exact element={<HomePage />} />
					<Route path="/page/shops" exact element={<ShopsPage />} />
					<Route path="/page/actualities" exact element={<ActualitiesPage />} />
					<Route path="/page/services" exact element={<ServicesPage />} />
					<Route path="/page/recrutement" exact element={<RecrutementPage />} />
					<Route path="/page/cgu" exact element={<CGUPage />} />

					<Route path="/header" exact element={<Header />} />
					<Route path="/press" exact element={<Press />} />

					<Route path="/blog/creation" exact element={<BlogCreation />} />
					<Route path="/blog/manager" exact element={<BlogManager />} />
					<Route path="/blog/all-articles" exact element={<BlogArticles />} />

					<Route path="*" element={<NotFound />} />
				</Routes>
			</HashRouter>
		</div>
	);
};

export default index;
