import React, { useContext } from "react";
import { UIdContext } from "../components/AppContext";
import TextEditor from "../components/TextEditor/TextEditor";

const Press = () => {
	const uid = useContext(UIdContext);
	return (
		<>
			{uid ? (
				<div className="page-context">
					<div className="press-page-container">
						<div className="press-container window-container">
							<h4>Presse</h4>
							<TextEditor textId={"press"} />
							<div className="unable">
								<p>Cet élément n'est plus à jour et plus modifiable</p>
							</div>
						</div>
					</div>
				</div>
			) : null}
		</>
	);
};

export default Press;
