import axios from "axios";

export const GET_ARTICLES = "GET_ARTICLES";

export const getArticles = (num) => {
	return (dispatch) => {
		return axios
			.get(`${process.env.REACT_APP_API_URL}api/blog/`)
			.then((res) => {
				const array = res.data.slice(0, num);
				dispatch({ type: GET_ARTICLES, payload: array });
			})
			.catch((err) => console.log(err));
	};
};
