import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "../Utils";

import { sliderModifier } from "../../actions/post.actions";

import AddBtn from "./AddBtn";
import Card from "./Card";

const Slider = (props) => {
	const posts = useSelector((state) => state.postReducer);
	const [sliderPics, setSliderPics] = useState([]);
	const dispatch = useDispatch();

	useEffect(() => {
		let array = [];
		for (let i = 0; i < posts.length; i++) {
			if (posts[i].container === props.context) {
				array.push(posts[i]);
			}
		}

		setSliderPics(array);
	}, [posts, props.context]);

	const handleSliderOrder = () => {
		let array = [];
		const sliderDraggable = Array.from(document.querySelectorAll(".slider-draggable"));

		for (let i = 0; i < sliderDraggable.length; i++) {
			let draggableId = sliderDraggable[i].id;
			let draggableValue = sliderDraggable[i].getAttribute("value");
			const draggableObject = {
				id: draggableId,
				value: draggableValue,
			};
			array.push(draggableObject);
		}
		const data = array;

		dispatch(sliderModifier(data));
	};

	return (
		<div>
			<div className="gray-separator"></div>
			<AddBtn props={props} />

			<ul>
				{!isEmpty(posts[0]) &&
					sliderPics.map((pic) => {
						return <Card pic={pic} key={pic._id} />;
					})}
			</ul>

			<form className="slider-form" action="" onSubmit={handleSliderOrder}>
				<input type="submit" value="Modifier l'ordre" className="classical-submit-btn slider-translate" />
			</form>
		</div>
	);
};

export default Slider;
