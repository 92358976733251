import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { uploadPicture } from "../actions/user.actions";

const UploadPicture = (props) => {
	const [file, setFile] = useState();
	const dispatch = useDispatch();
	const userData = useSelector((state) => state.userReducer);

	const handlePicture = (e) => {
		// e.preventDefault();

		const data = new FormData();
		data.append("userId", userData._id);
		data.append("container", props.context.context);
		data.append("file", file);

		dispatch(uploadPicture(data, props.context.context));
	};

	const closeUploadPic = (e) => {
		e.preventDefault();
		props.updatePic(false);
	};

	return (
		<div className="upload-new-pic-container">
			<div className="upload-new-pic window-container">
				<form action="" onSubmit={handlePicture} className="upload-pic">
					<h3>Ajouter une photo</h3>
					<button className="close-window principal-round-btn" onClick={closeUploadPic}>
						&#9587;
					</button>

					<div className="grey-separator"></div>

					<div className="upload-pic-container">
						{file ? (
							<div className="img-submit">
								<img className="img-preview" src={URL.createObjectURL(file)} alt="new-pic" />
								<input className="classical-submit-btn" type="submit" value="Enregistrer" />
							</div>
						) : (
							<>
								<label htmlFor="file" className="principal-submit-btn">
									+ Importer une photo
								</label>
								<input type="file" id="file" name="file" accept=".jpg, .jpeg, .png" onChange={(e) => setFile(e.target.files[0])} />
								<br />
							</>
						)}
					</div>
				</form>
			</div>
		</div>
	);
};

export default UploadPicture;
