import axios from "axios";
import React, { useState } from "react";
import ModifyPost from "../ModifyPost";
import PostDesactivation from "../PostDesactivation";
import TextEditor from "../TextEditor/TextEditor";

const Actuality = (props) => {
	const actuality = props.actuality;

	const [postModification, setPostModification] = useState(false);
	const [titleModification, setTitleModification] = useState(false);
	const [newTitle, setNewTitle] = useState(actuality.title);

	const handlePictureModification = () => {
		setPostModification(true);
	};

	const handleTitleModification = () => {
		setTitleModification(true);
	};
	const sendTitleModification = async (e) => {
		e.preventDefault();

		setTitleModification(false);

		await axios({
			method: "post",
			url: `${process.env.REACT_APP_API_URL}api/post/change-title/${actuality._id}`,
			withCredentials: true,
			data: {
				title: newTitle,
			},
		})
			.then((res) => {
				window.location.reload();
			})
			.catch((err) => {
				console.log(err);
			});
	};

	return (
		<>
			{postModification && <ModifyPost postModification={setPostModification} context={"actualities"} fileName={actuality.fileName} />}

			<li className="actualities-container window-container" key={props.id}>
				<div className="actualities-container__img">
					<img crossOrigin="anonymous" src={`${process.env.REACT_APP_IMG_API_URL}uploads/` + actuality.picture} alt="modify img" onClick={handlePictureModification} />
				</div>
				<div className="actualities-container__text">
					<div className="actualities-container__text--header">
						{!titleModification && <h4 onClick={handleTitleModification}>{newTitle}</h4>}
						{titleModification && (
							<div>
								<input type="text" placeholder={actuality.title} onChange={(e) => setNewTitle(e.target.value)} />
								<button className="classical-submit-btn" onClick={sendTitleModification}>
									Valider
								</button>
							</div>
						)}

						<PostDesactivation post={actuality} />
					</div>
					<div className="gray-separator"></div>
					<div className="actualities-container__text--text-editor">
						<TextEditor textId={actuality._id} />
					</div>
				</div>
			</li>
		</>
	);
};

export default Actuality;
