import axios from "axios";
import React, { useEffect, useState } from "react";

import Shop from "./Shop";

const ShopsList = (shopFilter) => {
	const [shopsList, setShopsList] = useState([]);

	useEffect(() => {
		const getShopsList = async () => {
			await axios({
				method: "get",
				url: `${process.env.REACT_APP_API_URL}api/shop`,
				withCredentials: true,
			})
				.then((res) => {
					setShopsList(res.data);
				})
				.catch((err) => {
					console.log(err);
				});
		};
		getShopsList();
	}, []);

	return (
		<div>
			<ul className="shops-list">
				{shopsList.map((shop, index) => {
					for (let i = 0; i < shopsList.length; i++) {
						if (shopFilter.shopFilter === undefined) {
							return (
								<li className="item" key={index}>
									<Shop shop={shop.data} id={shop._id} />
								</li>
							);
						} else if (shop.data.properties.storeid.toLowerCase().includes(shopFilter.shopFilter)) {
							return (
								<li className="item" key={index}>
									<Shop shop={shop.data} id={shop._id} />
								</li>
							);
						}
					}
					return "";
				})}
			</ul>
		</div>
	);
};

export default ShopsList;
