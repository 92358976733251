import React, { useState } from "react";
import UploadPicture from "../UploadPicture";

const AddBtn = ({ props }) => {
	const [updatePic, setUpdatePic] = useState(false);

	const handleAddSliderPic = () => {
		setUpdatePic(!updatePic);
	};

	return (
		<>
			<div className="slider-container__add-btn principal-round-btn">
				<img src="../assets/pictos/plus-solid.svg" alt="add-icon" onClick={handleAddSliderPic} />
			</div>
			{updatePic ? (
				<div className="update-pic-dom">
					<div className="update-pic-container">
						<UploadPicture updatePic={setUpdatePic} context={props} />
					</div>
				</div>
			) : null}
		</>
	);
};

export default AddBtn;
