import axios from "axios";
import React, { useState } from "react";
import ActionSubmited from "../ActionSubmited";

const Hours = ({ props, id }) => {
	// split hours
	const hoursArray = props.split("<br/>");

	const [mondayValue, setMondayValue] = useState(hoursArray[0]);
	const [mondayUpdate, setMondayUpdate] = useState(false);

	const [tuesdayValue, setTuesdayValue] = useState(hoursArray[1]);
	const [tuesdayUpdate, setTuesdayUpdate] = useState(false);

	const [wednesdayValue, setWednesdayValue] = useState(hoursArray[2]);
	const [wednesdayUpdate, setWednesdayUpdate] = useState(false);

	const [thursdayValue, setThursdayValue] = useState(hoursArray[3]);
	const [thursdayUpdate, setThursdayUpdate] = useState(false);

	const [fridayValue, setFridayValue] = useState(hoursArray[4]);
	const [fridayUpdate, setFridayUpdate] = useState(false);

	const [saturdayValue, setSaturdayValue] = useState(hoursArray[5]);
	const [saturdayUpdate, setSaturdayUpdate] = useState(false);

	const [sundayValue, setSundayValue] = useState(hoursArray[6]);
	const [sundayUpdate, setSundayUpdate] = useState(false);

	const [haveBeenModified, setHaveBeenModified] = useState(false);

	// monday update
	const handleMondayUpdate = () => {
		setMondayUpdate(!mondayUpdate);
	};
	const closeMonday = () => {
		setMondayUpdate(false);
	};

	// tuestday update
	const handleTuesdayUpdate = () => {
		setTuesdayUpdate(!tuesdayUpdate);
	};
	const closeTuesday = () => {
		setTuesdayUpdate(false);
	};

	// wednesday update
	const handleWednesdayUpdate = () => {
		setWednesdayUpdate(!wednesdayUpdate);
	};
	const closeWednesday = () => {
		setWednesdayUpdate(false);
	};
	// thursday update
	const handleThursdayUpdate = () => {
		setThursdayUpdate(!thursdayUpdate);
	};
	const closeThursday = () => {
		setThursdayUpdate(false);
	};
	// friday update
	const handleFridayUpdate = () => {
		setFridayUpdate(!fridayUpdate);
	};
	const closeFriday = () => {
		setFridayUpdate(false);
	};
	// saturday update
	const handleSaturdayUpdate = () => {
		setSaturdayUpdate(!saturdayUpdate);
	};
	const closeSaturday = () => {
		setSaturdayUpdate(false);
	};
	// sunday update
	const handleSundayUpdate = () => {
		setSundayUpdate(!sundayUpdate);
	};
	const closeSunday = () => {
		setSundayUpdate(false);
	};

	// submit
	const handleHoursSubmit = async (e) => {
		e.preventDefault();

		setHaveBeenModified(true);
		setTimeout(() => {
			setHaveBeenModified(false);
		}, 3000);

		let newShopHours = `${mondayValue}<br/>${tuesdayValue}<br/>${wednesdayValue}<br/>${thursdayValue}<br/>${fridayValue}<br/>${saturdayValue}<br/>${sundayValue}`;

		await axios({
			method: "post",
			url: `${process.env.REACT_APP_API_URL}api/shop/hours/${id}`,
			withCredentials: true,
			data: { newShopHours },
		})
			.then((res) => {
				return;
			})
			.catch((err) => {
				console.log(err);
			});
	};

	return (
		<div>
			{haveBeenModified && <ActionSubmited />}
			<div className="shop-hours">
				{/* monday  */}
				<p onClick={handleMondayUpdate} className="shop-monday">
					{mondayValue ? mondayValue : hoursArray[0]}
				</p>
				{mondayUpdate && (
					<>
						<input type="text" className="shop-hours-input" onChange={(e) => setMondayValue(e.target.value)} value={mondayValue} />
						<button
							id="mondaySubmit"
							onClick={(e) => {
								handleHoursSubmit(e);
								closeMonday();
							}}
							type="submit"
							className="shop-submit"
						>
							<img src="../../assets/pictos/paper-plane-solid.svg" alt="send" />
						</button>
					</>
				)}

				{/* tuesday  */}
				<p onClick={handleTuesdayUpdate} className="shop-tuesday">
					{tuesdayValue ? tuesdayValue : hoursArray[1]}
				</p>
				{tuesdayUpdate && (
					<>
						<input type="text" className="shop-hours-input" onChange={(e) => setTuesdayValue(e.target.value)} value={tuesdayValue} />
						<button
							id="tuesdaySubmit"
							onClick={(e) => {
								handleHoursSubmit(e);
								closeTuesday();
							}}
							type="submit"
							className="shop-submit"
						>
							<img src="../../assets/pictos/paper-plane-solid.svg" alt="send" />
						</button>
					</>
				)}

				{/* wednesday  */}
				<p onClick={handleWednesdayUpdate} className="shop-wednesday">
					{wednesdayValue ? wednesdayValue : hoursArray[2]}
				</p>
				{wednesdayUpdate && (
					<>
						<input type="text" className="shop-hours-input" onChange={(e) => setWednesdayValue(e.target.value)} value={wednesdayValue} />
						<button
							id="wednesdaySubmit"
							onClick={(e) => {
								handleHoursSubmit(e);
								closeWednesday();
							}}
							type="submit"
							className="shop-submit"
						>
							<img src="../../assets/pictos/paper-plane-solid.svg" alt="send" />
						</button>
					</>
				)}

				{/* thursday  */}
				<p onClick={handleThursdayUpdate} className="shop-thursday">
					{thursdayValue ? thursdayValue : hoursArray[3]}
				</p>
				{thursdayUpdate && (
					<>
						<input type="text" className="shop-hours-input" onChange={(e) => setThursdayValue(e.target.value)} value={thursdayValue} />
						<button
							id="thursdaySubmit"
							onClick={(e) => {
								handleHoursSubmit(e);
								closeThursday();
							}}
							type="submit"
							className="shop-submit"
						>
							<img src="../../assets/pictos/paper-plane-solid.svg" alt="send" />
						</button>
					</>
				)}

				{/* friday  */}
				<p onClick={handleFridayUpdate} className="shop-friday">
					{fridayValue ? fridayValue : hoursArray[4]}
				</p>
				{fridayUpdate && (
					<>
						<input type="text" className="shop-hours-input" onChange={(e) => setFridayValue(e.target.value)} value={fridayValue} />
						<button
							id="fridaySubmit"
							onClick={(e) => {
								handleHoursSubmit(e);
								closeFriday();
							}}
							type="submit"
							className="shop-submit"
						>
							<img src="../../assets/pictos/paper-plane-solid.svg" alt="send" />
						</button>
					</>
				)}

				{/* saturday  */}
				<p onClick={handleSaturdayUpdate} className="shop-saturday">
					{saturdayValue ? saturdayValue : hoursArray[5]}
				</p>
				{saturdayUpdate && (
					<>
						<input type="text" className="shop-hours-input" onChange={(e) => setSaturdayValue(e.target.value)} value={saturdayValue} />
						<button
							id="saturdaySubmit"
							onClick={(e) => {
								handleHoursSubmit(e);
								closeSaturday();
							}}
							type="submit"
							className="shop-submit"
						>
							<img src="../../assets/pictos/paper-plane-solid.svg" alt="send" />
						</button>
					</>
				)}

				{/* sunday  */}
				<p onClick={handleSundayUpdate} className="shop-sunday">
					{sundayValue ? sundayValue : hoursArray[6]}
				</p>
				{sundayUpdate && (
					<>
						<input type="text" className="shop-hours-input" onChange={(e) => setSundayValue(e.target.value)} value={sundayValue} />
						<button
							id="sundaySubmit"
							onClick={(e) => {
								handleHoursSubmit(e);
								closeSunday();
							}}
							type="submit"
							className="shop-submit"
						>
							<img src="../../assets/pictos/paper-plane-solid.svg" alt="send" />
						</button>
					</>
				)}
			</div>
		</div>
	);
};

export default Hours;

// "Lundi: 15h00-20h00</br>Mardi: 10h00-20h30</br>Mercredi: 10h00-20h30</br>Jeudi: 10h00-20h30</br>Vendredi: 10h00-20h30</br>Samedi: 10h00-20h30</br>Dimanche: 10h00-13h00"
// Lundi: 15h00-20h30<br/>Mardi: 10h00-20h30<br/>Mercredi: 10h00-20h30<br/>Jeudi: 10h00-20h30<br/>Vendredi: 10h00-20h30<br/>Samedi: 10h00-20h30<br/>Dimanche: 10h00-13h00
