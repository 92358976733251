import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { UIdContext } from "../components/AppContext";
import Recrutement from "../components/Recrutement/Recrutement";
import { isEmpty } from "../components/Utils";

const RecrutementPage = () => {
	const uid = useContext(UIdContext);

	const posts = useSelector((state) => state.postReducer);
	const [recrutementPosts, setRecrutementPosts] = useState([]);

	useEffect(() => {
		let array = [];
		for (let i = 0; i < posts.length; i++) {
			if (posts[i].container === "recrutement") {
				array.push(posts[i]);
			}
		}

		setRecrutementPosts(array);
	}, [posts]);
	return (
		<>
			{uid ? (
				<div className="page-context">
					<div className="recrutements-page-container">
						<ul>
							{!isEmpty(posts[0]) &&
								recrutementPosts.map((recrutement, index) => {
									return <Recrutement recrutement={recrutement} id={recrutement._id} index={index} />;
								})}
						</ul>
					</div>
				</div>
			) : null}
		</>
	);
};

export default RecrutementPage;
