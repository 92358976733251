import React, { useEffect, useState } from "react";
import axios from "axios";
import ActiveArticle from "./ActiveArticle";

const CategoryModification = ({ category, categoryModification }) => {
	const [articleList, setArticleList] = useState([]);

	const closeCategoryModification = () => {
		categoryModification(false);
	};

	useEffect(() => {
		const getArticlesList = async () => {
			await axios({
				method: "get",
				url: `${process.env.REACT_APP_API_URL}api/blog`,
				withCredentials: true,
			})
				.then((res) => {
					let array = [];
					for (let i = 0; i < res.data.length; i++) {
						if (res.data[i].rubrique === category && res.data[i].isActive === true) {
							array.push(res.data[i]);
						}
					}
					setArticleList(array);
				})
				.catch((err) => {
					console.log(err);
				});
		};
		getArticlesList();
	}, [category]);

	return (
		<>
			<div className="category-modification-container">
				<div className="category-modification window-container">
					<div className="category-modification-header">
						<h3>Modification de la catégorie</h3>
						<button className="close-window principal-round-btn" onClick={closeCategoryModification}>
							&#9587;
						</button>
					</div>
					<ul className="category-modification-articles">
						{articleList.map((article) => {
							return <ActiveArticle article={article} />;
						})}
					</ul>
				</div>
			</div>
		</>
	);
};

export default CategoryModification;
