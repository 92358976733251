import React, { useContext } from "react";
import { UIdContext } from "../components/AppContext";
import { NavLink } from "react-router-dom";

const NotFound = () => {
	const uid = useContext(UIdContext);
	return (
		<>
			{uid ? (
				<div className="page-context">
					<div className="not-found-page-container">
						<div className="not-found-container">
							<img className="ico" src="../assets/pictos/not-found.svg" alt="not found" />
							<h1>Cette page n'est pas disponible</h1>
							<p>Le lien peut être non valide ou la page a peut-être été supprimée. Vérifiez si le lien que vous essayez d’ouvrir est correct.</p>
							<NavLink to="/">
								<button className="principal-submit-btn">Retourner à l'accueil</button>
							</NavLink>
						</div>
					</div>
				</div>
			) : null}
		</>
	);
};

export default NotFound;
