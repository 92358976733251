import { GET_ARTICLES } from "../actions/article.actions";

const initialState = {};

export default function postReducer(state = initialState, action) {
	switch (action.type) {
		case GET_ARTICLES:
			return action.payload;

		default:
			return state;
	}
}
