import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { UIdContext } from "../components/AppContext";
import { isEmpty } from "../components/Utils";

import Cart from "../components/Header/Cart";

const Header = () => {
	const uid = useContext(UIdContext);

	const posts = useSelector((state) => state.postReducer);
	const [headerPost, setHeaderPost] = useState([]);

	useEffect(() => {
		let array = [];
		for (let i = 0; i < posts.length; i++) {
			if (posts[i].container === "header") {
				array.push(posts[i]);
			}
		}
		setHeaderPost(array);
	}, [posts]);

	return (
		<>
			{uid ? (
				<div className="page-context">
					<div className="header-page-container">
						<div className="header-container window-container">
							<div className="header-text">
								<h4>Édition du header</h4>
								<div className="gray-separator"></div>
								<h5 className="disclaimer">Affiche ou désactive une rubrique du site</h5>
							</div>
							<div className="header-sections">
								<ul className="actuality-container__actuality">
									{!isEmpty(headerPost[0]) &&
										headerPost[0].header.map((headerSection, index) => {
											return <Cart id={index} cart={headerSection} />;
										})}
								</ul>
							</div>
						</div>
					</div>
				</div>
			) : null}
		</>
	);
};

export default Header;
