import { UIdContext } from "../components/AppContext";
import React, { useContext } from "react";

import Slider from "../components/Slider/Slider";
import AboutUs from "../components/Home/AboutUs";
import Pictos from "../components/Home/Pictos";
import FooterPics from "../components/Home/FooterPics";

const HomePage = () => {
	const uid = useContext(UIdContext);

	return (
		<>
			{uid ? (
				<div className="page-context">
					<div className="home-page-container">
						<div className="home-page-container__slider">
							<div className="slider-dom-container">
								<div className="slider-container window-container">
									<h4>Modifier le carrousel</h4>
									<Slider context={"homeslider"} />
								</div>
							</div>
						</div>
						<div className="home-page-container__about-us">
							<AboutUs />
							<Pictos />
							<FooterPics />
						</div>
					</div>
				</div>
			) : null}
		</>
	);
};

export default HomePage;
