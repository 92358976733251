import axios from "axios";
import React from "react";

const PostDesactivation = ({ post }) => {
	const handlePostActivation = async () => {
		post.isActive = !post.isActive;
		await axios({
			method: "post",
			url: `${process.env.REACT_APP_API_URL}api/post/is-active/${post._id}`,
			withCredentials: true,
			data: {
				isActive: post.isActive,
			},
		})
			.then((res) => {
				window.location.reload();
			})
			.catch((err) => {
				console.log(err);
			});
	};

	return (
		<div className="post-desactivation">
			<div className="desactivate-post-btn" onClick={handlePostActivation}>
				{post.isActive ? "Désactiver" : "Activer"}
			</div>
		</div>
	);
};

export default PostDesactivation;
