import React, { useState } from "react";
import ModifyPost from "../ModifyPost";

const FooterPic = (props) => {
	const [postModification, setPostModification] = useState(false);
	const picInfo = props.pic;

	const handlePictureModification = () => {
		setPostModification(true);
	};

	return (
		<>
			{postModification && <ModifyPost postModification={setPostModification} context={"aboutus-footer"} fileName={picInfo.fileName} />}
			<div className="picture-container" onClick={handlePictureModification}>
				<img crossOrigin="anonymous" src={`${process.env.REACT_APP_IMG_API_URL}uploads/` + picInfo.picture} alt="fresque img" />
			</div>
		</>
	);
};

export default FooterPic;
