import React, { useState } from "react";
import ModifyPost from "../ModifyPost";

const Picto = (props) => {
	const picto = props.picto;

	const [postModification, setPostModification] = useState(false);

	const handlePostModification = () => {
		setPostModification(true);
	};

	return (
		<>
			{postModification && <ModifyPost postModification={setPostModification} context={"pictos"} text={true} fileName={"picto-" + props.index + ".jpg"} placeholder={picto.text} />}
			<div className="pictos-container__pictos--picto" onClick={handlePostModification}>
				<img crossOrigin="anonymous" src={`${process.env.REACT_APP_IMG_API_URL}uploads/` + picto.picture} alt="picto " />
				<h5>{picto.text}</h5>
			</div>
		</>
	);
};

export default Picto;
