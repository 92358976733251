import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { UIdContext } from "../components/AppContext";

import Service from "../components/Services/Service";
import { isEmpty } from "../components/Utils";

const ServicesPage = () => {
	const uid = useContext(UIdContext);

	const posts = useSelector((state) => state.postReducer);
	const [servicesPosts, setServicesPosts] = useState([]);

	useEffect(() => {
		let array = [];
		for (let i = 0; i < posts.length; i++) {
			if (posts[i].container === "services") {
				array.push(posts[i]);
			}
		}

		setServicesPosts(array);
	}, [posts]);

	return (
		<>
			{uid ? (
				<div className="page-context">
					<div className="services-page-container">
						<ul>
							{!isEmpty(posts[0]) &&
								servicesPosts.map((service, index) => {
									return <Service service={service} id={service._id} index={index} />;
								})}
						</ul>
					</div>
				</div>
			) : null}
		</>
	);
};

export default ServicesPage;
