import React from "react";
import axios from "axios";
import cookie from "js-cookie";

const Logout = () => {
	const removeCookie = (key) => {
		if (window !== "undefined") {
			cookie.remove(key, { expires: 1 });
		}
	};

	const logout = async () => {
		await axios({
			method: "get",
			url: `${process.env.REACT_APP_API_URL}api/user/logout`,
			withCredentials: true,
		})
			.then(() => {
				console.log("tataru");
				removeCookie("jwt");
			})
			.catch((err) => console.log(err));

		window.location = "/";
	};

	return (
		<div className="logout" onClick={logout}>
			<img src="../assets/pictos/right-from-bracket-solid.svg" alt="logout" />
		</div>
	);
};

export default Logout;
