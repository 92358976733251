import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { UIdContext } from "./AppContext";

import Log from "../components/Log";
import Logout from "./Log/Logout";

const PrincipalNavBar = () => {
	const uid = useContext(UIdContext);
	return (
		<>
			{uid ? (
				<div className="principal-nav-bar-container">
					<header className="principal-nav-bar">
						<div className="principal-nav-bar__user">
							<NavLink to="/">
								<div className="principal-nav-bar__user--pic principal-round-btn">
									<img src="../assets/pictos/user-regular.svg" alt="user" />
								</div>
								<h2>Admin</h2>
							</NavLink>
							<div className="logout">
								<Logout />
							</div>
						</div>
						<div className="gray-separator"></div>

						<div className="principal-nav-bar__section">
							<h4>Pages</h4>
							<ul>
								<li>
									<NavLink to="/page/home" className={(state) => (state.isActive ? "principal-nav-bar__btn principal-nav-bar__btn--is-active" : "principal-nav-bar__btn")}>
										<div className="principal-nav-bar__btn--pic principal-round-btn">
											<img src="../assets/pictos/house-chimney-solid.svg" alt="home" />
										</div>
										<h5>Home</h5>
									</NavLink>
								</li>
								<li>
									<NavLink to="/page/shops" className={(state) => (state.isActive ? "principal-nav-bar__btn principal-nav-bar__btn--is-active" : "principal-nav-bar__btn")}>
										<div className="principal-nav-bar__btn--pic principal-round-btn">
											<img src="../assets/pictos/shop-solid.svg" alt="boutiques" />
										</div>
										<h5>Boutiques</h5>
									</NavLink>
								</li>
								<li>
									<NavLink to="/page/actualities" className={(state) => (state.isActive ? "principal-nav-bar__btn principal-nav-bar__btn--is-active" : "principal-nav-bar__btn")}>
										<div className="principal-nav-bar__btn--pic principal-round-btn">
											<img src="../assets/pictos/calendar-plus-solid.svg" alt="actualités" className="press" />
										</div>
										<h5>Actualités</h5>
									</NavLink>
								</li>
								<li>
									<NavLink to="/page/services" className={(state) => (state.isActive ? "principal-nav-bar__btn principal-nav-bar__btn--is-active" : "principal-nav-bar__btn")}>
										<div className="principal-nav-bar__btn--pic principal-round-btn">
											<img src="../assets/pictos/truck-ramp-box-solid.svg" alt="services" />
										</div>
										<h5>Services</h5>
									</NavLink>
								</li>
								<li>
									<NavLink to="/page/recrutement" className={(state) => (state.isActive ? "principal-nav-bar__btn principal-nav-bar__btn--is-active" : "principal-nav-bar__btn")}>
										<div className="principal-nav-bar__btn--pic principal-round-btn">
											<img src="../assets/pictos/person-circle-plus-solid.svg" alt="recrutement" />
										</div>
										<h5>Recrutement</h5>
									</NavLink>
								</li>
								<li>
									<NavLink to="/page/cgu" className={(state) => (state.isActive ? "principal-nav-bar__btn principal-nav-bar__btn--is-active" : "principal-nav-bar__btn")}>
										<div className="principal-nav-bar__btn--pic principal-round-btn">
											<img src="../assets/pictos/scale-balanced-solid.svg" alt="CGU" />
										</div>
										<h5>CGU</h5>
									</NavLink>
								</li>
							</ul>
						</div>
						<div className="gray-separator"></div>

						<div className="principal-nav-bar__section">
							<h4>Header &amp; Presse</h4>
							<ul>
								<li>
									<NavLink to="/header" className={(state) => (state.isActive ? "principal-nav-bar__btn principal-nav-bar__btn--is-active" : "principal-nav-bar__btn")}>
										<div className="principal-nav-bar__btn--pic principal-round-btn">H</div>
										<h5>Header</h5>
									</NavLink>
								</li>
								<li>
									<NavLink to="/press" className={(state) => (state.isActive ? "principal-nav-bar__btn principal-nav-bar__btn--is-active" : "principal-nav-bar__btn")}>
										<div className="principal-nav-bar__btn--pic principal-round-btn">
											<img src="../assets/pictos/file-contract-solid.svg" alt="press" className="press" />
										</div>
										<h5>Presse</h5>
									</NavLink>
								</li>
							</ul>
						</div>
						<div className="gray-separator"></div>

						<div className="principal-nav-bar__section">
							<h4>Blog</h4>
							<ul>
								<li>
									<NavLink to="/blog/creation" className={(state) => (state.isActive ? "principal-nav-bar__btn principal-nav-bar__btn--is-active" : "principal-nav-bar__btn")}>
										<div className="principal-nav-bar__btn--pic principal-round-btn">
											<img src="../assets/pictos/plus-solid.svg" alt="nouveau" />
										</div>
										<h5>Nouveau</h5>
									</NavLink>
								</li>
								<li>
									<NavLink to="/blog/manager" className={(state) => (state.isActive ? "principal-nav-bar__btn principal-nav-bar__btn--is-active" : "principal-nav-bar__btn")}>
										<div className="principal-nav-bar__btn--pic principal-round-btn">
											<img src="../assets/pictos/list-check-solid.svg" alt="manager" />
										</div>
										<h5>Manager</h5>
									</NavLink>
								</li>
							</ul>
						</div>
					</header>
				</div>
			) : (
				<Log />
			)}
		</>
	);
};

export default PrincipalNavBar;
